import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = ({ author, title }) => (
  <div className="bg-darkGrey footer">
    <div className="container">
      
    </div>
  </div>
)

export default Footer
