import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.scss'

class Navi extends React.Component {
	constructor(props){
		super(props)
		this.state = {show: false};
		this.handleScroll = this.handleScroll.bind(this);


	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}
	handleScroll(event){
		console.log(window.scrollY);
		if(window.scrollY > 350){
			this.setState({show: true});
		}else{
			this.setState({show: false});
		}
	}
  render() {
    const { location, title } = this.props
    return (
      <nav className="px-0 w-100 d-block flex-column flex-md-row">
                  
            <div className={this.state.show ? "col-md-6 offset-md-6 d-flex bar-show" : "col-md-6 offset-md-6 d-flex"} >
              {/* <Link className="text-center" to="/">
                <h1 className="navbar-brand mb-0">{title}</h1>
              </Link> */}
			  <div className={this.state.show ? "logo show" : "logo"}>
				  @raes_of_love
			  </div>
              <div className="navbar-nav-scroll w-100">
                <ul className="navbar-nav bd-navbar-nav flex-row justify-content-end w-100">
                   {/* <li
                    className={
                      location.pathname === '/' ? 'nav-item active' : 'nav-item'
                    }
                  >
                    <Link to="/" className="nav-link mr-3">
                      Home
                    </Link>
                  </li> */}
                  <li
                    className={
                      location.pathname === '/'
                        ? 'nav-item active'
                        : 'nav-item'
                    }
                  >
                    {/* <Link to="/" className="nav-link mr-3">
                    	Our Products
                    </Link> */}
                  </li>
				  {/* <li className="nav-item" ><a href="#" className="nav-link snipcart-checkout d-flex icon">
				  		<FontAwesomeIcon icon="shopping-cart" />
					  <div className="snipcart-summary"><span className="snipcart-total-items"></span></div>Cart</a>
					</li> */}
                </ul>
              </div>

          
        </div>
      </nav>
    )
  }
}

export default Navi
