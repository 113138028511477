import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import get from 'lodash/get'
import map from 'lodash/map'

import Adsense from 'components/adsense'

import './style.scss'

const Post = ({ data, options }) => {
  const {
    category,
    tags,
    description,
    title,
    id,
    price,
    path,
    date,
	image,
	gallery,
  } = data.frontmatter
  console.log(data.frontmatter);
//   const { isIndex, adsense } = options
//   const html = get(data, 'html')

  const fluid = get(image, 'childImageSharp.fluid')
  console.log(gallery);
  const image_url = get(image, 'childImageSharp.original.src');
  let images = gallery.map(image => {return {url: image.childImageSharp.fluid.src}});
  console.log(images);
  return (
    <div className="article mb-2 mt-2 " key={path}>
      
          <div className="col-md-6 offset-md-6 px-4 px-md-5">
              {/* {fluid ? (
                <Img className="image-border" fluid={fluid} style={{ display: 'block', margin: '0' }} />
              ) : (
                ''
              )} */}
			  
			  <Carousel>
                {images.map(image => (
					<div>
						<img src={image.url} alt=""/>
					</div>
				))}
			  </Carousel>
            </div>
          <div className="content col-md-6 offset-md-6 px-4 px-md-5">            
            <div className="info mt-3">
              
              <h1>{title}</h1>
              
              {/* {Badges({ items: [category], primary: true })}
              {Badges({ items: tags })} */}
            </div>
            <p>{description}</p>
            <h2>${price}</h2>
			<h4>Order by emailing <a href="mailto:raesofloveflorals@gmail.com">raesofloveflorals@gmail.com.</a></h4>
			{/* {id === "3" ? (
				<button className="snipcart-add-item btn btn-primary mb-5"
				data-item-id={id}
				data-item-price={price}
				data-item-url={`https://raesoflove.ca${path}`}
				data-item-description={description}
				data-item-image={image_url}
				data-item-custom1-name="Type"
  			  	data-item-custom1-options="White|Whole Wheat"
				data-item-name={title}>
				Add to cart
			  </button>
			) : (
			<button className="snipcart-add-item btn btn-primary mb-5"
              data-item-id={id}
              data-item-price={price}
			  data-item-url={`https://raesoflove.ca${path}`}
              data-item-description={description}
			  data-item-image={image_url}
			  data-item-stackable="false"
			  data-item-custom1-name="Delivery tag message"
              data-item-name={title}>
				  
              Add to cart
            </button>
			)} */}
            <hr />
          </div>

    </div>
  )
}

export default Post

const Badges = ({ items, primary }) =>
  map(items, (item, i) => {
    return (
      <span
        className={`badge ${primary ? 'badge-primary' : 'badge-secondary'}`}
        key={i}
      >
        {item}
      </span>
    )
  })
